import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-device-spec-list',
  templateUrl: './device-spec-list.component.html',
  styleUrls: ['./device-spec-list.component.scss'],
})

/**
 * デバイス詳細情報の一覧に使用する部品
 */
export class DeviceSpecListComponent implements OnInit {

  /** デバイス詳細情報 */
  @Input() displayItems: [string, string][];

  /** コンストラクタ */
  constructor() { }

  /** 初期化処理 */
  ngOnInit() {
  }
}

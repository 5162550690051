import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {

  /** タイトル */
  @Input() title: string;

  /** コンストラクタ */
  constructor() { }

  /** 初期化処理 */
  ngOnInit() {}

}
